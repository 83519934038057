.ant-layout {
    background-color: #e5e5e5 !important;
}
#components-layout-demo-fixed-sider .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
    background: #fff;
}
.ant-layout-sider-children {
    background-color: #65666b;
}
.ant-menu.ant-menu-dark {
    background-color: #65666b !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #65666b !important;
}
.ant-layout-sider-trigger {
    background-color: #65666b !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #acacac !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #3e3f41 !important;
}
.text-center {
    padding: 40px 20px 20px 20px;
}
.menu-text-header {
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 10px;
    display: block;
}