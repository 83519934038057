
.login-background {
    background-color: #65666b;
    height: 100vh;
    position: relative;
}
.img-fluid {
    display: block;
    margin: 0 auto;
}
.login-form-container {
    padding: 10% 0px;
}
.text-center {
    text-align: center;
}
.login-form-button {
    background-color: #39b64b !important;
    border-color: #39b64b !important;
    height: 40px !important;
    border-radius: 12px !important;
    width: 100% !important;
}
.login-header {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
    display: block;
}
