.page-title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 37px;
    color: #000000;
    display: block;
}
.ant-card-head-title {
    color: white !important;
}

.id-css {
    /* unique ID: 46500-011 */


    font-weight: 600;
    font-size: 18px;

    color: #000000;


}
.popover.show {
    max-width: 400px;
    background-color: #65666B;
    color: #fff;
}

.heading-page {


    font-weight: bold;
    font-size: 18px;

    color: #000000;
    margin-top: 20px;
    display: block;
}
.form-control {
    background-color: #C4C4C4 !important;
    border-radius: 12px !important;
}
.form-select {
    background-color: #C4C4C4 !important;
    border-radius: 12px !important;
}
.card-datavisulization {
    height: 150px;
    background-color: #C4C4C4;
    border-radius: 12px;
    text-align: center;
    vertical-align: middle;
    margin: 10px 0px;
}

/*.ant-picker-dropdown {*/
/*    z-index: 999999 !important;*/
/*}*/

.fixed-header thead th { /* the thead selector is required because there are th elements in the body */
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}