.page-title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 37px;
    color: #000000;
    display: block;
}
.ant-card-head-title {
    color: white !important;
}
.id-css {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}
.heading-page {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin-top: 20px;
    display: block;
}
.form-control {
    background-color: #C4C4C4 !important;
    border-radius: 12px !important;
}
.form-select {
    background-color: #C4C4C4 !important;
    border-radius: 12px !important;
}
.card-datavisulization-user {
    height: 150px;
    background-color: #C4C4C4;
    border-radius: 12px;
    text-align: center;
    vertical-align: middle;
    margin: 10px 0px;
}
.edit-button {
    background-color: #39b64b !important;
    border-color: #39b64b !important;
    height: 40px !important;
    /*border-radius: 12px !important;*/
    width: 30% !important;
}
.assign-button {
    background-color: #128620 !important;
    border-color: #128620 !important;
    height: 40px !important;
    /*border-radius: 12px !important;*/
    width: 30% !important;
}
.remove-button {
    background-color: #ff1a1a !important;
    border-color: #ff1a1a !important;
    height: 40px !important;
    /*border-radius: 12px !important;*/
    width: 30% !important;
}
