.page-title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 37px;
    color: #000000;
    display: block;
}
.ant-card-head-title {
    color: white !important;
}
.card-datavisulization1 {
    padding: 10px;
    background-color: #C4C4C4;
    border-radius: 12px;
    margin: 10px 0px;
}