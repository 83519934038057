.card-graph-visualization {
    height: 80%;
    background-color: #ffffff;
    border-radius: 12px;
    text-align: center;
    vertical-align: middle;
    margin: 10px 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10%;
}

.bar-graph-visualization {
    height: 75%;
    background-color: #ffffff;
    border-radius: 12px;
    text-align: center;
    vertical-align: middle;
    margin: 10px 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10%;
}
